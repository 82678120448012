import React, { RefObject, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { getProfile } from "../../../api/consultants-api";
import { consultant } from "../../../model";
import { getMonthlyInvoice, getMonthlyItemizedInvoice, getWeeklyInvoice } from "../../../api/finance-api";
import moment from "moment";
import { nanoid } from "nanoid";
import Moment from "react-moment";
import Currency from "react-currency-formatter";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Skeleton from "react-loading-skeleton";

export default function Invoice() {
    const { uuid, _m, _y, _w, _type, _invNo, _d } = useParams();
    console.log(useParams());

    const [consultant, setConsultant] = useState<consultant>();
    const [items, setItems] = useState<any>();

    const [metadata, setMetadata] = useState<any>();

    //document.getElementById("invoiceCard")
    const reportTemplateRef = useRef() as RefObject<HTMLDivElement>;

    useEffect(() => {
        // setElementOutlet(reportTemplateRef.current);

        // Load Consultant Profile using UUID
        getProfile(uuid).then((r) => {
            console.log(r);

            setConsultant(r[0]);

            if (r[0]?.billing_period === "Monthly") {
                generateMonthlyInvoice();
            } else if (r[0]?.billing_period === "Monthly Itemized") {
                generateMonthlyItemizedInvoice();
            } else if (r[0]?.billing_period === "Weekly") {
                generateWeeklyInvoice();
            } else {
                generateMonthlyInvoice();
            }
        });
    }, []);

    function generateMonthlyInvoice(): any {
        console.log(_y);
        console.log(_m);

        getMonthlyInvoice({
            uuid: uuid,
            checkDate: moment()
                .set("year", Number(_y))
                .set("month", Number(_m) - 1)
                .toDate(),
        }).then((r) => {
            console.log(r);
            setItems(r);

            if (r && r.length > 0) {
                let _sbill: number =
                    r &&
                    r
                        .map((a) => a._sbill)
                        .reduce(function (a, b) {
                            return a + b;
                        }, 0);

                let _shrs: number =
                    r &&
                    r
                        .map((a) => a._shrs)
                        .reduce(function (a, b) {
                            return a + b;
                        }, 0);

                let _spayment: number =
                    r &&
                    r
                        .map((a) => a._spayment)
                        .reduce(function (a, b) {
                            return a + b;
                        }, 0);

                if (r[1]) {
                    setMetadata({
                        ...metadata,
                        invoiceNo: r[1]?.invoice_no,
                        invoiceDate: r[1]?._ts_invoice,
                        invoiceDueDate: r[1]?._ts_invoice_due,
                        title: moment.utc(r[1]?._d).format("MMMM YYYY"),
                        _spayment: _spayment,
                        _sbill: _sbill,
                        _shrs: _shrs,
                    });
                } else if (r[0]) {
                    setMetadata({
                        ...metadata,
                        invoiceNo: r[0]?.invoice_no,
                        invoiceDate: r[0]?._ts_invoice,
                        invoiceDueDate: r[0]?._ts_invoice_due,
                        title: moment.utc(r[0]?._d).format("MMMM YYYY"),
                        _spayment: _spayment,
                        _sbill: _sbill,
                        _shrs: _shrs,
                    });
                } else {
                    console.error(r);
                }

                console.log(r[0]);
            }

            console.log(metadata);
        });
    }

    function generateWeeklyInvoice(): any {
        console.log(_y);
        console.log(_m);

        getWeeklyInvoice({
            uuid: uuid,
            checkDate: moment()
                .set("year", Number(_y))
                .set("month", Number(_m) - 1)
                .toDate(),
            week: Number(_w) - 1,
        }).then((r) => {
            console.log(r);
            setItems(r);

            if (r && r.length > 0) {
                let _sbill: number = r
                    .map((a) => a._sbill)
                    .reduce(function (a, b) {
                        return a + b;
                    });

                let _shrs: number = r
                    .map((a) => a._shrs)
                    .reduce(function (a, b) {
                        return a + b;
                    });

                let _spayment: number = r
                    .map((a) => a._spayment)
                    .reduce(function (a, b) {
                        return a + b;
                    });

                console.log(r[0]);
                setMetadata({ ...metadata, invoiceNo: r[1]?.invoice_no, invoiceDate: r[1]?._ts_invoice, invoiceDueDate: r[1]?._ts_invoice_due, title: "", _spayment: _spayment, _sbill: _sbill, _shrs: _shrs });
                console.log(metadata);
            }
        });
    }

    function generateMonthlyItemizedInvoice(): any {
        console.log(_y);
        console.log(_m);

        getMonthlyItemizedInvoice({
            uuid: uuid,
            checkDate: moment()
                .set("year", Number(_y))
                .set("month", Number(_m) - 1)
                .toDate(),
        }).then((r) => {
            console.log(r);
            setItems(r);

            if (r && r.length > 0) {
                let _sbill: number = r
                    .map((a) => a._sbill)
                    .reduce(function (a, b) {
                        return a + b;
                    });

                let _shrs: number = r
                    .map((a) => a._shrs)
                    .reduce(function (a, b) {
                        return a + b;
                    });

                let _spayment: number = r
                    .map((a) => a._spayment)
                    .reduce(function (a, b) {
                        return a + b;
                    });

                console.log(r);
                setMetadata({ ...metadata, invoiceNo: r[1]?.invoice_no, invoiceDate: r[1]?._ts_invoice, invoiceDueDate: r[1]?._ts_invoice_due, title: "", _spayment: _spayment, _sbill: _sbill, _shrs: _shrs });
                console.log(metadata);
            }
        });
    }

    const onGeneratePDF = () => {
        console.log("onGeneratePDF");
        const input = document.getElementById("invoiceCard")!;
        const divHeight = input?.clientHeight;
        const divWidth = input?.clientWidth;
        const ratio = divHeight / divWidth;

        html2canvas(input, { scale: 5 }).then((canvas) => {
            const imgData = canvas.toDataURL("image/png", 1.0);
            const pdf = new jsPDF({
                orientation: "landscape",
                // format: "a4",
                // unit: "px",
            });
            const imgProps = pdf.getImageProperties(imgData);
            const Width = pdf.internal.pageSize.getWidth();
            let Height = (imgProps.height * Width) / imgProps.width;
            Height = ratio * Width;

            var img_w = canvas.width;
            var img_h = canvas.height;

            pdf.addImage(imgData, "JPEG", 50, 0, Width - 100, Height - 100);
            pdf.save("Invoice" + " - " + consultant?.name + " - " + moment.utc(_d).format("MMMM YYYY") + ".pdf");
            // checkDate: new Date(moment.utc(row.original._d).format("YYYY-MM-DD HH:mm:ss")),

            // pdf.html(reportTemplateRef.current, {
            //     async callback(doc) {
            //         await doc.save("document");
            //     },
            // });
        });
    };

    const toUTCFormat = (d: any) => {
        // console.log(d);
        return moment.utc(d).format("MMMM DD, YYYY"); //MM/DD/YYYY
        // return moment(d).add(1, "day").format();
    };

    const toUTCFormat1 = (d: any) => {
        // console.log(d);
        return moment.utc(d).format("MMMM DD"); //MM/DD/YYYY
        // return moment(d).add(1, "day").format();
    };

    return (
        <>
            <main className="bg-gray-100 dark:bg-slate-900">
                <div className="max-w-[85rem] px-4 sm:px-6 lg:px-8 mx-auto my-4 sm:my-10">
                    <div className="mx-auto sm:w-11/12 lg:w-3/4">
                        <div className="flex flex-col p-4 bg-white shadow-md sm:p-10 rounded-xl dark:bg-gray-800" id="invoiceCard" ref={reportTemplateRef}>
                            <div className="flex justify-between">
                                <div>
                                    <img className="h-20 mx-auto mt-4 ml-0" src="/mti-logo.png" alt="MTINC" />
                                </div>
                                {/* <div>02</div> */}
                                <div>
                                    <h2 className="text-xl font-semibold text-gray-800 md:text-xl dark:text-gray-200">Mind Tech Inc.</h2>
                                    <address className="flex block mt-0 text-sm not-italic text-gray-600 dark:text-gray-200">
                                        10998, S. WilCrest, Suite-272, <br /> Houston, TX - 77099
                                    </address>
                                    <div className="mt-0">
                                        <p>
                                            <span className="text-sm font-medium text-gray-500">www.mindtechinc.com</span>
                                        </p>
                                        <p>
                                            <span className="text-sm font-medium text-gray-500">accounts@mindtechinc.com</span>
                                        </p>
                                        <p>
                                            <span className="text-sm font-medium text-gray-500">+1 (281) 369-4409</span>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="flex justify-center px-5 my-3 ">
                                <h2 className="text-base font-semibold text-center text-black md:text-xl dark:text-gray-200">Invoice</h2>
                                {/* <hr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" /> */}
                            </div>

                            <div className="flex justify-between">
                                <div>
                                    <h2 className="text-sm font-normal text-gray-800 md:text-base dark:text-gray-200">Bill To</h2>
                                    {consultant?.account && (
                                        <>
                                            <h3 className="pt-0 font-semibold text-gray-800 text-md dark:text-gray-200">{consultant?.client || consultant?.account}</h3>
                                            <h4 className="text-sm font-normal text-gray-800 dark:text-gray-200">{consultant?.client_address}</h4>
                                        </>
                                    )}
                                </div>
                                <div className="block mt-0 text-gray-500 text-md">
                                    <span className="block">
                                        <b>Invoice No.:</b> {metadata?.invoiceNo || _invNo}
                                    </span>
                                    <span className="block">
                                        {/* format="MMMM DD, YYYY" */}
                                        <b>Invoice Date:</b> {moment.utc(_d).add(1, "M").format("MMMM DD, YYYY")}
                                        {/* <Moment filter={toUTCFormat}>
                                            {metadata?.invoiceDate || _d}
                                        </Moment> */}
                                    </span>
                                </div>
                            </div>

                            <div className="flex my-3 text-md justify-left">
                                Resource Name: &nbsp; <span className="font-semibold"> {consultant?.name}</span>, &nbsp; <span className="font-normal"> {consultant?.project}</span>
                                {/* <h2 className="text-base font-semibold text-left text-black md:text-md dark:text-gray-200">Invoice No.</h2> */}
                            </div>

                            {/* {items && items.length > 0 && ( */}
                            <div className="relative pt-0 overflow-x-auto">
                                <table className="w-full text-sm text-left text-gray-500 rtl:text-right dark:text-gray-400">
                                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                        <tr>
                                            <th scope="col" className="px-6 py-3 text-left">
                                                Item
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-right">
                                                Qty (Hrs)
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-right">
                                                Rate (USD)
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-right">
                                                Amount (USD)
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!items && (
                                            <tr>
                                                <td colSpan={4}>
                                                    <Skeleton count={2} />
                                                </td>
                                            </tr>
                                        )}

                                        {items &&
                                            items.length > 0 &&
                                            items.map((item) => (
                                                <tr key={nanoid()} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                                    <td scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                        {metadata?.title}
                                                        {item.first_day_of_week && item.last_day_of_week && (
                                                            <>
                                                                {/* format="MMMM DD" */}
                                                                {/* filter={toUTCFormat1} */}
                                                                <Moment filter={toUTCFormat1}>{item.first_day_of_week}</Moment> {/* format="MMMM DD, YYYY" */}-{/* filter={toUTCFormat} */}
                                                                <Moment filter={toUTCFormat}>{item.last_day_of_week}</Moment>
                                                            </>
                                                        )}
                                                    </td>
                                                    <td className="px-6 py-2 text-right">{item._shrs}</td>
                                                    <td className="px-6 py-2 text-right">
                                                        <Currency quantity={item.rate || item.maxrate || 0} currency="USD" />
                                                    </td>
                                                    <td className="px-6 py-2 text-right">
                                                        <Currency quantity={item._sbill || 0} currency="USD" />
                                                    </td>
                                                </tr>
                                            ))}

                                        <tr key={nanoid()} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                            <td className="col-span-2 px-6 py-2 font-medium text-left whitespace-nowrap dark:text-white" colSpan={1}>
                                                <span className="font-semibold text-gray-800 dark:text-gray-200">Total </span>
                                            </td>
                                            <td className="col-span-1 px-6 py-2 font-medium text-right whitespace-nowrap dark:text-white" colSpan={1}>
                                                {/* <span className="font-semibold text-gray-800 dark:text-gray-200">Total: </span> */}
                                                <span>{metadata?._shrs || 0}</span>
                                            </td>
                                            <td className="col-span-1 px-6 py-2 font-medium text-right whitespace-nowrap dark:text-white" colSpan={2}>
                                                {/* <span className="font-semibold text-gray-800 dark:text-gray-200">Total: </span> */}
                                                <Currency quantity={metadata?._sbill || 0} currency="USD" />
                                            </td>
                                        </tr>

                                        {/* {metadata?._spayment > 0 && (
                                            <tr key={nanoid()} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                                <td className="col-span-4 px-6 py-2 font-medium text-right whitespace-nowrap dark:text-white" colSpan={4}>
                                                    <span className="font-semibold text-gray-800 dark:text-gray-200">Amount paid: </span>
                                                    <Currency quantity={metadata?._spayment || 0} currency="USD" />
                                                </td>
                                            </tr>
                                        )}

                                        {metadata?._spayment > 0 && (
                                            <tr key={nanoid()} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                                <td className="col-span-4 px-6 py-2 font-medium text-right whitespace-nowrap dark:text-white" colSpan={4}>
                                                    <span className="font-semibold text-gray-800 dark:text-gray-200">Due Balance: </span>
                                                    <Currency quantity={metadata?._sbill - metadata?._spayment || 0} currency="USD" />
                                                </td>
                                            </tr>
                                        )} */}
                                    </tbody>
                                </table>
                            </div>

                            {/* )} */}
                            {/* {!items && <Skeleton count={3} />} */}
                            {items && items.length > 0 && false && (
                                <div className="flex mt-5 sm:justify-end">
                                    <div className="w-full max-w-xs space-y-2 sm:text-end">
                                        <div className="grid grid-cols-2 gap-3 sm:grid-cols-1 sm:gap-2">
                                            {/* <dl className="grid sm:grid-cols-5 gap-x-3">
                                            <dt className="col-span-3 font-semibold text-gray-800 dark:text-gray-200">Subtotal:</dt>
                                            <dd className="col-span-2 text-gray-500">$2750.00</dd>
                                        </dl> */}

                                            <dl className="grid sm:grid-cols-5 gap-x-3">
                                                <dt className="col-span-3 font-semibold text-gray-800 dark:text-gray-200">Total:</dt>
                                                <dd className="col-span-2 text-gray-500">
                                                    <Currency quantity={metadata?._sbill || 0} currency="USD" />
                                                </dd>
                                            </dl>

                                            {/* <dl className="grid sm:grid-cols-5 gap-x-3">
                                            <dt className="col-span-3 font-semibold text-gray-800 dark:text-gray-200">Tax:</dt>
                                            <dd className="col-span-2 text-gray-500">$39.00</dd>
                                        </dl> */}

                                            {/* {metadata?._spayment > 0 && (
                                                <dl className="grid px-6 py-2 text-right sm:grid-cols-5 gap-x-3">
                                                    <dt className="col-span-3 font-semibold text-gray-800 dark:text-gray-200">Amount paid:</dt>
                                                    <dd className="col-span-2 text-gray-500">
                                                        <Currency quantity={metadata?._spayment || 0} currency="USD" />
                                                    </dd>
                                                </dl>
                                            )}

                                            {metadata?._spayment > 0 && (
                                                <dl className="grid sm:grid-cols-5 gap-x-3">
                                                    <dt className="col-span-3 font-semibold text-gray-800 dark:text-gray-200">Due Balance:</dt>
                                                    <dd className="col-span-2 text-gray-500">
                                                        <Currency quantity={metadata?._sbill - metadata?._spayment || 0} currency="USD" />
                                                    </dd>
                                                </dl>
                                            )} */}
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="flex gap-2 pt-2 overflow-x-auto place-content-right">
                                <table className="text-sm text-left text-gray-500 w-2xl rtl:text-right dark:text-gray-400">
                                    <tbody>
                                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                            <td className="px-6 py-2 text-right">Remit Payment To</td>
                                            <td className="px-6 py-2 text-right">Mind Tech Inc.</td>
                                        </tr>
                                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                            <td className="px-6 py-2 text-right">EIN #</td>
                                            <td className="px-6 py-2 text-right">47-1995113</td>
                                        </tr>
                                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                            <td className="px-6 py-2 text-right">Phone No.</td>
                                            <td className="px-6 py-2 text-right">646-790-9628</td>
                                        </tr>
                                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                            <td className="px-6 py-2 text-right">Email</td>
                                            <td className="px-6 py-2 text-right">accounts@mindtechinc.com</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="mt-5 sm:mt-6">
                                <p className="mb-2 text-sm text-gray-500">
                                    Please make payment of{" "}
                                    <b>
                                        <Currency quantity={metadata?._sbill - metadata?._spayment || 0} currency="USD" />
                                    </b>{" "}
                                    to <b>MindTech Inc.</b> at <span className="">10998, S. WilCrest, Suite-272, Houston, TX - 77099</span>
                                </p>
                            </div>

                            <div className="mt-4 sm:mt-5">
                                {/* <h4 className="font-semibold text-gray-800 text-md dark:text-gray-200">Thank you!</h4> */}
                                {/* <p className="text-sm text-gray-500">If you have any questions concerning this invoice, use the following contact information:</p> */}
                                {/* <div className="mt-1">
                                    <span className="text-sm font-medium text-blue-800">support@mindtechinc.com</span> <span className="text-gray-500">|</span> <span className="text-sm font-medium text-blue-800">+1 (281) 369-4409</span>
                                </div> */}
                            </div>
                            {/* <p className="mt-2 text-xs text-gray-400">This invoice is computer-generated and does not require a signature.</p>
                            <p className="mt-3 text-sm text-gray-500">
                                © {new Date().getFullYear()} <b>Mind Tech Inc.</b>
                            </p> */}
                        </div>

                        <div className="flex justify-end mt-6 gap-x-3">
                            <a onClick={() => onGeneratePDF()} className="inline-flex items-center justify-center gap-2 px-3 py-2 text-sm text-gray-700 align-middle transition-all bg-white border rounded-lg shadow-sm font-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 dark:bg-gray-800 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800" href="#">
                                <svg className="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 4" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                                    <polyline points="7 10 12 15 17 10" />
                                    <line x1="12" x2="12" y1="15" y2="3" />
                                </svg>
                                Download Invoice PDF
                            </a>
                            {/* <a className="inline-flex items-center px-3 py-2 text-sm font-semibold text-white bg-blue-900 border border-transparent rounded-lg gap-x-2 hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="#">
                                <svg className="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round">
                                    <polyline points="6 9 6 2 18 2 18 9" />
                                    <path d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2" />
                                    <rect width="12" height="8" x="6" y="14" />
                                </svg>
                                Print
                            </a> */}
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}
function setElementOutlet(current: string) {
    throw new Error("Function not implemented.");
}
